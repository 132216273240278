import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./main.scss";

Vue.config.productionTip = false;
Vue.prototype.$FHIR = window.FHIR;

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");
