export function formatDebug(message) {
    const time = new Date();
    const formatterOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
        timeZoneName: "short"
    };
    const formatter = new Intl.DateTimeFormat("en-US", formatterOptions);
    const formattedTime = formatter.format(time);

    return "[" + formattedTime + "] " + message + "\n";
}
