import * as Constants from "@/constants/misc-constants";
import * as Errors from "@/constants/errors";

export function toEhrError(
    error,
    errorUrl = Constants.NOT_APPLICABLE,
    errorLevel = Errors.ERROR_LEVELS.ERROR
) {
    const ehrError = { message: "", url: errorUrl, level: errorLevel };

    // Exit if already in the correct format
    if (error.message && error.url && error.level !== undefined) {
        return error;
    }

    // Handle error with URL
    if (error.message && error.url) {
        ehrError.message = error.message;
        ehrError.url = error.url;

        // Handle errors from API (axios)
    } else if (isApiErrorWithResponse(error)) {
        ehrError.message = error.error.message;
        ehrError.url = error.error.config
            ? error.error.config.url
            : Constants.NOT_APPLICABLE;

        // Handle strings
    } else if (typeof error === Constants.STRING_TYPE) {
        ehrError.message = error;

        // Handle regular javascript errors with message content
    } else if (error.message) {
        ehrError.message = error.message;

        // Throw generic error message
    } else {
        ehrError.message = Errors.UNKNOWN_INTERNAL_ERROR;
    }

    return ehrError;
}

function isApiErrorWithResponse(err) {
    return err.error && err.error.response;
}

export function isEhrApiDatabaseException(err) {
    return (
        isApiErrorWithResponse(err) &&
        err.error.response.data &&
        err.error.response.data.error === Errors.DATABASE_EXCEPTION
    );
}

export function is401Error(err) {
    return isApiErrorWithResponse(err) && err.error.response.status === 401;
}

export function is403Error(err) {
    return isApiErrorWithResponse(err) && err.error.response.status === 403;
}

export function handleErrorFromEhrSessionCreator(err) {
    let ehrError = err;
    if (err.error && err.error.message === Errors.NETWORK_ERROR) {
        ehrError = {
            message: Errors.UNABLE_TO_REACH_EHRSC,
            url: err.error.config.url
        };
    } else if (is403Error(err)) {
        ehrError = {
            message: Errors.USER_CONFIGURATION_ISSUE + " " + Errors.ERROR_403,
            url: err.error.config.url
        };
    }

    throw toEhrError(ehrError);
}

export function handleErrorFromEhrApi(err) {
    let ehrApiError = err;
    if (err.error && err.error.message === Errors.NETWORK_ERROR) {
        ehrApiError = {
            message: Errors.UNABLE_TO_REACH_API,
            url: err.error.config.url
        };
    } else if (isEhrApiDatabaseException(err)) {
        ehrApiError = {
            message: Errors.TENANT_IS_NOT_REGISTERED_IN_API,
            url: err.error.config.url
        };
    }

    throw toEhrError(ehrApiError);
}
