import * as Constants from "@/constants/misc-constants";
import * as Errors from "@/constants/errors";
import { EHR_PROVIDERS as EhrProviders } from "@/constants/providers";
import { CASE_DOC_VIEWS as CaseDocumentationViews } from "@/constants/case-doc-views";

function translateContextVariableToCaseDocumentationView(contextVariable) {
    if (!contextVariable) {
        throw Errors.EHR_CONTEXT_VAR_MISSING;
    }
    if (contextVariable == Constants.OR) {
        return CaseDocumentationViews.OR;
    }
    if (contextVariable == Constants.CATHIR) {
        return CaseDocumentationViews.CATHIR;
    }
    throw Errors.EHR_CONTEXT_VAR_HAS_INVALID_DATA;
}

export function getCaseDocumentationViewFromTokenResponse(
    provider,
    tokenResponse
) {
    if (provider === EhrProviders.CERNER) {
        return translateContextVariableToCaseDocumentationView(
            tokenResponse.cerner_launch_case_doc_view
        );
    } else if (provider === EhrProviders.EPIC) {
        return translateContextVariableToCaseDocumentationView(
            tokenResponse.epic_launch_case_doc_view
        );
    }
}
