import { EHR_PROVIDERS as EhrProviders } from "@/constants/providers";
import * as Constants from "@/constants/misc-constants";

export function extractEncounterId(context, encounter) {
    if (context.getters.provider === EhrProviders.CERNER) {
        // Encounter ID is the first identifier on the Encounter resource of type "FIN"
        const finEncounters = encounter.identifier.filter((id) =>
            id.type.text.includes(Constants.CERNER_ENCOUNTER_IDENTIFIER)
        );
        if (finEncounters.length !== 0) {
            return finEncounters[0].value;
        }
    } else if (context.getters.provider === EhrProviders.EPIC) {
        // Encounter ID is the first identifier in the array (there should only be 1)
        return encounter.identifier[0].value;
    }
}
