// Constants File for Store mutations and actions

export const MUTATIONS = {
    SET_ENCOUNTER_ID: "SET_ENCOUNTER_ID",
    SET_CASE_DOC_VIEW: "SET_CASE_DOC_VIEW",
    SET_TENANT_AND_TOKENS: "SET_TENANT_AND_TOKENS",
    SET_URL: "SET_URL",
    SET_LOGIN_SUCCESSFUL: "SET_LOGIN_SUCCESSFUL",
    SET_HTTP_ERROR: "SET_HTTP_ERROR",
    SET_PROVIDER: "SET_PROVIDER",
    SET_VERSION: "SET_VERSION",
    SET_CASE_ID: "SET_CASE_ID",
    SET_SEND_ACCESS_TOKEN: "SET_SEND_ACCESS_TOKEN",
    APPEND_DEBUG_MESSAGE: "APPEND_DEBUG_MESSAGE"
};

export const ACTIONS = {
    READ_ENCOUNTER_FROM_FHIR_API: "readEncounterFromFhirApi",
    SET_CASE_DOCUMENTATION_VIEW: "setCaseDocumentationView",
    SET_TENANT_AND_TOKENS: "setTenantAndTokens",
    FETCH_ITOPIA_URL: "fetchItopiaUrl",
    ITOPIA_LOGIN: "itopiaLogin",
    SET_PROVIDER: "setProvider",
    SET_VERSION: "setVersion",
    SET_CASE_ID: "setCaseId",
    APPEND_DEBUG_MESSAGE: "appendDebugMessage"
};
